<template>
  <v-row>
    <v-col cols="6" align="center">
      <v-btn
        small
        min-width="140"
        max-width="140"
        min-height="35"
        class="py-4"
        :color="networkTransition ? 'primary' : undefined"
        @click="updateTransitions(NETWORK_TRANSITION)"
        ><v-icon>mdi-exit-run</v-icon>
        <p class="text-wrap" style="width: min-content; margin: auto">
          {{ $t('Exit to network') }}
        </p>
      </v-btn>
    </v-col>
    <v-col cols="6" align="center">
      <v-btn
        small
        min-width="140"
        max-width="140"
        min-height="35"
        class="py-4"
        :color="siteTransition ? 'primary' : undefined"
        @click="updateTransitions(SITE_TRANSITION)"
        ><v-icon>mdi-map-marker-distance</v-icon>
        <p class="text-wrap" style="width: min-content; margin: auto">
          {{ $t('Site transition') }}
        </p>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import constants from '~/shared/constants';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'EntryPointPanel',

  props: [],

  data() {
    return {};
  },

  computed: {
    ...mapGetters('entryPoint', ['networkTransition', 'siteTransition']),
    NETWORK_TRANSITION() {
      return constants.EXCHANGE_POINT_TYPES.NETWORK_TRANSITION;
    },
    SITE_TRANSITION() {
      return constants.EXCHANGE_POINT_TYPES.SITE_TRANSITION;
    }
  },

  methods: {
    ...mapActions('entryPoint', ['setTransition']),
    updateTransitions(type) {
      this.setTransition(type);
      this.$emit('setTransition');
    }
  }
};
</script>
